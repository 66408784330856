<template>
  <div class="news-query-whole">
    <div class="news-query-breadcrumb-div">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item @click.native="backList"
          >公司新闻</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ newsDetail.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news-query-title">{{ newsDetail.intro }}</div>
    <!-- <div class="news-query-img">
      <img :src="this.$request.getHost() + newsDetail.cover" alt="" />
    </div> -->

    <div
      class="html ql-container ql-snow news-query-content"
      style="border: none"
    >
      <div
        class="ql-editor"
        data-gramm="false"
        contenteditable="false"
        data-placeholder="请输入内容"
        v-html="newsDetail.content"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    newsDetail: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {}
  },
  methods: {
    backList() {
      console.warn('backList')
      this.$emit('backList')
    },
  },
}
</script>
<style lang="scss" scoped>
@function sizeScale($oriValue, $ratio) {
  $unit: 1px;
  @return ($oriValue * $ratio) * $unit;
}

.news-query-whole {
  width: 1200px;
  margin: 0 auto;
  .news-query-breadcrumb-div {
    margin: 60px 0;
    font-size: 30px;
  }
  .news-query-title {
    font-size: 26px;
    font-weight: 400;
    color: #333333;
    margin: 40px 0;
    text-align: center;
  }
  .news-query-content {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    margin: 40px 0;
  }
  .news-query-img {
    width: 1200px;
    height: 484px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.el-breadcrumb {
  //elementui面包屑字体大小
  font-size: 30px !important;
}

@media only screen and (max-width: 750px) {
  .news-query-whole {
    width: sizeScale(1200,1.5);

    .news-query-breadcrumb-div {
      margin: sizeScale(60,2) 0;
      font-size: sizeScale(30,2);
    }
    .news-query-title {
      font-size: sizeScale(26,2);
      margin: sizeScale(40,2) 0;
    }
    .news-query-content {
      font-size: sizeScale(20,2);
      margin: sizeScale(40,2) 0;
    }
    .news-query-img {
      width:sizeScale(1200,2);
      height: sizeScale(484,2);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .el-breadcrumb {
    //elementui面包屑字体大小
    font-size: sizeScale(30,2) !important;
  }
}
</style>