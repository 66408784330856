<template>
  <div class="home">
    <div class="home-title">
      <div class="home-left">
        <img src="image/home-logo.png" alt="" />
        <div>沈阳积莱商贸有限公司</div>
      </div>
      <div class="home-right">
        <div
          :class="tabIndex == index ? 'home-right-btns' : 'home-right-btn'"
          v-for="(item, index) in tabs"
          :key="index"
          @click="tabBtn(index)"
        >
          {{ item.title }}
        </div>
      </div>
      <i class="home-right-drawer el-icon-s-grid" @click="drawer = true"></i>

      <!-- :size="200 * tabs.length"内联转不了 -->
      <el-drawer
        :append-to-body="true"
        :visible.sync="drawer"
        direction="ttb"
        size="auto"
        :show-close="false"
        custom-class="drawer"
        class="outterDrawer"
      >
        <div
          class="menu_item"
          v-for="(item, index) in tabs"
          :key="index"
          @click="tabBtn(index)"
        >
          {{ item.title }}
        </div>
      </el-drawer>
    </div>
    <div class="main-figure">
      <img src="image/home-main-figure.png" alt="" />
    </div>
    <blockquote v-if="tabIndex == 0">
      <div class="product-center">
        <div class="product-right-img">
          <img src="image/home-module-build.png" alt="" />
        </div>
        <div class="product-centent-wrap">
          <div class="specific-project-wrap">
            <div v-for="(item, index) in products.slice(1)" :key="index">
              <!-- <div :style="{visibility:(index?'visible':'hidden')}"> -->
              <div class="specific-project">
                <div class="project-img-num">
                  <img src="image/home-module-number.png" alt="" />
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="project-text">
                  <div class="project-title">{{ item.title }}</div>
                  <!-- <div class="project-english">
                  {{ item.englishTitle }}
                </div> -->
                  <div :class="index == 1 ? 'project-hr' : 'project-hrs'"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-left-module">
          <div class="module-title-english">Product Cen<span>ter</span></div>
          <div class="module-title">产品中心</div>
          <div class="module-btn" @click="tabIndex = 6">
            <span>立刻申请合作</span>
            <span>+</span>
          </div>
        </div>
      </div>
      <div class="about">
        <div class="about-img">
          <img src="image/home-about-right-build.png" alt="" />
        </div>
        <div class="about-wrap">
          <div class="about-left-img">
            <img src="image/home-about-build.png" alt="" />
          </div>
          <div class="about-title">ABOUT<span>US</span></div>
          <div class="about-title2">
            <div class="about-title2-hr"></div>
            <span>关于我们</span>
          </div>
          <div class="about-content">
            沈阳积莱商贸有限公司成立于2017年。公司以尊重科学，注重质量，精诚服务的
            经营宗旨，致力于医疗器械行业各类医疗设备及耗材的销售服务。
          </div>
          <div class="about-btn" @click="tabIndex = 6">详情咨询 +</div>
        </div>
      </div>
    </blockquote>
    <blockquote v-if="tabIndex == 1">
      <div class="supplies">
        <div class="news">
          <div class="news-title">公司产品</div>
          <div class="news-hr">
            <div class="hr"></div>
            <div class="hr"></div>
          </div>
          <div class="news-english-title">COMPANY PRODUCTS</div>
        </div>
        <div class="main-supplies">
          <div
            v-for="(type, index) in productTypeList"
            :key="'t_' + index"
            class="main-img"
          >
            <!-- @click="queryProduct(type.id)" -->
            <img :src="$request.getHost() + type.cover" alt="" />
          </div>
        </div>
        <!-- <div class="supplies-module">
          <div class="supplies-list">
            <div class="list" v-for="(item, index) in productList" :key="index">
              <div class="list-img">
                <img :src="$request.getHost() + item.cover" />
              </div>
              <div class="list-title">{{ item.name }}</div>
              <div class="list-negotiable">{{ item.price }}</div>
              <div
                :class="item.status == 0 ? 'list-btn' : 'list-btns'"
                @click="browseDetail(item.id)"
              >
                查看详情
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="pages" v-show="paginationParams.totalPage">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="paginationParams.pageSize"
          layout="prev, pager, next, jumper"
          :total="paginationParams.totalRow"
          prev-text="<上一页"
          next-text="下一页>"
        >
        </el-pagination>
        <!-- <div class="number">共{{ paginationParams.totalPage }}页</div> -->
      </div>
    </blockquote>
    <blockquote v-if="tabIndex == 2">
      <companyProfile :content="companyData.c_content"></companyProfile>
    </blockquote>
    <!-- 新闻列表 -->
    <blockquote v-if="(tabIndex == 0 || tabIndex == 3) && newsStatus == false">
      <div class="news">
        <div class="news-title">公司新闻</div>
        <div class="news-hr">
          <div class="hr"></div>
          <div class="hr"></div>
        </div>
        <div class="news-english-title">Company news</div>
        <div class="news-wrap">
          <div class="news-list">
            <div
              class="news"
              v-for="(news, index) in newsList"
              :key="'n_' + index"
              @click="newsOpen(news.id)"
            >
              <div class="news-img">
                <img :src="$request.getHost() + news.cover" alt="" />
              </div>
              <div class="news-text">
                <div class="news-text-title">{{ news.title }}</div>
                <div class="news-text-content">
                  {{ news.intro }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </blockquote>
    <!-- 新闻详情 -->
    <template v-if="tabIndex == 3 && newsStatus" @click="newsClose">
      <companyNewsQuery
        @backList="newsStatus = false"
        :newsDetail="newsDetail"
      ></companyNewsQuery>
    </template>
    <blockquote v-if="tabIndex == 3 && !newsStatus">
      <!-- <div class="news-more">查看更多</div> -->
    </blockquote>
    <blockquote v-if="tabIndex == 0 || tabIndex == 4">
      <div style="padding-bottom: 40px">
        <div class="news">
          <div class="news-title">公司档案</div>
          <div class="news-hr">
            <div class="hr"></div>
            <div class="hr"></div>
          </div>
          <div class="news-english-title">Company files</div>
          <div class="compony-table">
            <div class="table">
              <div
                class="table-top"
                v-for="(item, index) in compony"
                :key="index"
              >
                <div class="table-left">{{ item.name }}</div>
                <div class="table-right">{{ item.title }}</div>
              </div>
            </div>
            <div class="tables">
              <div
                class="table-tops"
                v-for="(item, index) in compony1"
                :key="index"
              >
                <div class="table-lefts">{{ item.name }}</div>
                <div class="table-rights">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </blockquote>
    <blockquote v-if="tabIndex == 0 && certList.home.length">
      <div class="news">
        <div class="news-title">荣誉资质</div>
        <div class="news-hr">
          <div class="hr"></div>
          <div class="hr"></div>
        </div>
        <div class="news-english-title">Honor and qualification</div>
        <div class="qualification">
          <div
            class="qualification-img"
            v-for="(cert, index) in certList.home"
            :key="'c_' + index"
          >
            <img :src="$request.getHost() + cert.cover" alt="" />
          </div>
        </div>
      </div>
    </blockquote>
    <blockquote v-if="tabIndex == 5">
      <div class="news">
        <div class="news-title">荣誉资质</div>
        <div class="news-hr">
          <div class="hr"></div>
          <div class="hr"></div>
        </div>
        <div class="news-english-title">Honor and qualification</div>
        <div
          v-if="certList.basic.length"
          class="qualification-title"
          style="margin-top: 30px"
        >
          <div class="qualification-hr"></div>
          <span>基本资格证书</span>
        </div>
        <div class="qualification">
          <div
            class="qualification-img"
            v-for="(basic, index) in certList.basic"
            :key="'basic_' + index"
          >
            <img :src="$request.getHost() + basic.cover" alt="" />
          </div>
        </div>
        <div v-if="certList.business.length" class="qualification-title">
          <div class="qualification-hr"></div>
          <span>企业自传荣誉资质</span>
        </div>
        <div class="qualification">
          <div
            class="qualification-img"
            v-for="(business, index) in certList.business"
            :key="'business_' + index"
          >
            <img :src="$request.getHost() + business.cover" alt="" />
          </div>
        </div>
      </div>
    </blockquote>
    <blockquote v-if="tabIndex == 0">
      <div class="contacts fix-height">
        <div class="contact-back-img">
          <img src="image/contact-people.png" alt="" />
        </div>
        <div class="contact">
          <div class="contact-wrap">
            <div class="contact-english-title">CONTACT<span>US</span></div>
            <div class="contact-title">
              <div class="contact-hr"></div>
              <span>联系我们</span>
            </div>
            <div class="contact-content">
              <div class="content-yellow"></div>
              <div class="content-wrap">
                <div class="content">联系人：{{ companyData.contact }}</div>
                <div class="content">固话：{{ companyData.phone }}</div>
                <div class="content">手机：{{ companyData.mobile }}</div>
                <div class="content">传真：{{ companyData.fax }}</div>
                <div class="content">地址：{{ companyData.address }}</div>
                <div class="content">邮编：{{ companyData.postal }}</div>
                <div class="content">官方网址：{{ companyData.website }}</div>
              </div>
            </div>
            <div class="contact-left-bulid">
              <img src="image/contact-right-bulid.png" alt="" />
            </div>
            <div class="contact-right-bulid">
              <img src="image/contact-left-bulid.png" alt="" />
            </div>
            <div class="contact-from">
              <div class="form-title">
                <div class="from-left">主题</div>
                <div class="from-right">
                  <input type="text" v-model="submitForm.title" />
                </div>
              </div>
              <div class="form-title">
                <div class="from-left">内容</div>
                <div class="from-right">
                  <input type="text" v-model="submitForm.content" />
                </div>
              </div>
              <div class="form-title">
                <div class="from-left">公司名称</div>
                <div class="from-right">
                  <input type="text" v-model="submitForm.company_name" />
                </div>
              </div>
              <div class="form-title">
                <div class="from-left"><span>*</span>联系姓名</div>
                <div class="from-right">
                  <input type="text" v-model="submitForm.contact_name" />
                </div>
              </div>
              <div class="form-title">
                <div class="from-left"><span>*</span>联系电话</div>
                <div class="from-right">
                  <input type="text" v-model="submitForm.contact_phone" />
                </div>
              </div>
              <div class="form-title">
                <div class="from-left">微信号</div>
                <div class="from-right">
                  <input type="text" v-model="submitForm.wx" />
                </div>
              </div>
              <div class="form-title">
                <div class="from-left">验证码</div>
                <div class="from-right1">
                  <input type="text" maxlength="4" v-model="iptValidCode" />
                </div>
                <div class="from-code">
                  <div class="code">{{ validCode }}</div>
                </div>
                <div class="from-tips" @click="createValidCode">
                  看不清换一张
                </div>
              </div>
              <div class="from-btn" @click="limit">立即提交</div>
            </div>
          </div>
        </div>
      </div>
    </blockquote>
    <blockquote v-if="tabIndex == 6">
      <div class="news">
        <div class="news-title">联系我们</div>
        <div class="news-hr">
          <div class="hr"></div>
          <div class="hr"></div>
        </div>
        <div class="news-english-title">Contact us</div>
        <div class="infor-wrap">
          <div class="infor">
            <img src="image/infor-name.png" alt="" />
            <div class="infor-title">联系人</div>
            <div class="infor-title">{{ companyData.contact }}</div>
          </div>
          <div class="infor">
            <img src="image/infor-phone.png" alt="" />
            <div class="infor-title">固定电话</div>
            <div class="infor-title">{{ companyData.phone }}</div>
          </div>
          <div class="infor">
            <img src="image/infor-mobile.png" alt="" />
            <div class="infor-title">手机</div>
            <div class="infor-title">{{ companyData.mobile }}</div>
          </div>
          <div class="infor">
            <img src="image/infor-zip-code.png" alt="" />
            <div class="infor-title">邮编</div>
            <div class="infor-title">{{ companyData.postal }}</div>
          </div>
          <div class="infor">
            <img src="image/infor-url.png" alt="" />
            <div class="infor-title">官方网址</div>
            <div class="infor-title">{{ companyData.website }}</div>
          </div>
        </div>
      </div>
      <div class="map-forms">
        <div class="froms-wrap">
          <div class="froms-title">
            <div class="froms-left">主题</div>
            <div class="froms-right">
              <input type="text" v-model="submitForm.title" />
            </div>
          </div>
          <div class="froms-title">
            <div class="froms-left">内容</div>
            <div class="froms-right">
              <input type="text" v-model="submitForm.content" />
            </div>
          </div>
          <div class="froms-title">
            <div class="froms-left">公司名称</div>
            <div class="froms-right">
              <input type="text" v-model="submitForm.company_name" />
            </div>
          </div>
          <div class="froms-title">
            <div class="froms-left"><span>*</span>联系姓名</div>
            <div class="froms-right">
              <input type="text" v-model="submitForm.contact_name" />
            </div>
          </div>
          <div class="froms-title">
            <div class="froms-left"><span>*</span>联系电话</div>
            <div class="froms-right">
              <input type="text" v-model="submitForm.contact_phone" />
            </div>
          </div>
          <div class="froms-title">
            <div class="froms-left">微信号</div>
            <div class="froms-right">
              <input type="text" v-model="submitForm.wx" />
            </div>
          </div>
          <div class="froms-title">
            <div class="froms-left">验证码</div>
            <div class="froms-right1">
              <input type="text" maxlength="4" v-model="iptValidCode" />
            </div>
            <div class="froms-code">
              <div class="code">{{ validCode }}</div>
            </div>
            <div class="froms-tips" @click="createValidCode">看不清换一张</div>
          </div>
          <div class="froms-btn" @click="limit">立即提交</div>
        </div>
        <div class="map-wrap">
          <div class="map"><img src="image/infor-map.png" alt="" /></div>
          <div class="map-address">
            <img src="image/infor-location.png" alt="" />
            <div class="address">地址：{{ companyData.address }}</div>
          </div>
        </div>
      </div>
    </blockquote>

    <!-- <div class="afterwards" :style="tabIndex == 0 ? 'margin-top:260px;' : ''">
      <div class="afterwards-left">
        <div class="afterwards-content" v-for="(item, index) in 1" :key="index">
          <div class="afterwards-block">
            <div class="afterwards-title">快速导航</div>
            <div class="afterwards-text" @click="tabIndex = 1">产品中心</div>
            <div class="afterwards-text" @click="tabIndex = 2">公司介绍</div>
            <div class="afterwards-text" @click="tabIndex = 3">公司新闻</div>
          </div>
          <div  class="afterwards-block">
            <div style="visibility:hidden" class="afterwards-title">快速导航</div>
            <div class="afterwards-text" @click="tabIndex = 4">诚信档案</div>
            <div class="afterwards-text" @click="tabIndex = 5">荣誉资质</div>
            <div class="afterwards-text" @click="tabIndex = 6">联系我们</div>
          </div>
          
        </div>
        
      </div>
      <div class="afterwards-right">
        <img :src="$request.getHost() + companyData.code" alt="" />
      </div>
      <div class="web-record">网站备案号：辽ICP备2021008947号</div>
    </div> -->

    <div class="bottom-nav">
      <div class="title">帮助中心</div>
      <div class="option-block">
        <div class="row-one">
          <div class="quick-option" @click="tabIndex = 1">产品中心</div>
          <div class="quick-option" @click="tabIndex = 2">公司介绍</div>
          <div class="quick-option" @click="tabIndex = 3">公司新闻</div>
        </div>
        <div class="row-two">
          <div class="quick-option" @click="tabIndex = 4">诚信档案</div>
          <div class="quick-option" @click="tabIndex = 5">荣誉资质</div>
          <div class="quick-option" @click="tabIndex = 6">联系我们</div>
        </div>
      </div>
      <div class="web-record">网站备案号：辽ICP备2021008947号</div>
    </div>

    <el-dialog
      class="product-detail"
      title="产品详情"
      :visible.sync="detaildialogVisible"
    >
      <div class="html ql-container ql-snow" style="border: none">
        <div
          class="ql-editor"
          data-gramm="false"
          contenteditable="false"
          data-placeholder="请输入内容"
          v-html="productDetail"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import companyProfile from '../components/company-profile/company-profile.vue'
import companyNewsQuery from '../components/company-news-query/company-news-query.vue'
export default {
  components: {
    companyProfile,
    companyNewsQuery,
  },
  data() {
    return {
      tabs: [
        {
          title: '首页',
        },
        {
          title: '产品中心',
        },
        {
          title: '公司介绍',
        },
        {
          title: '公司新闻',
        },
        {
          title: '诚信档案',
        },
        {
          title: '荣誉资质',
        },
        {
          title: '联系我们',
        },
      ],
      tabIndex: 0,
      // products: [
      //   {
      //     title: '',
      //     englishTitle: '',
      //   },
      //   {
      //     title: '医疗器械',
      //     englishTitle: 'Medical apparatus andinstruments',
      //   },
      //   {
      //     title: '教学办公',
      //     englishTitle: 'Teaching office',
      //   },
      //   {
      //     title: '技术服务',
      //     englishTitle: 'Technical service',
      //   },
      //   {
      //     title: '日用百货',
      //     englishTitle: 'General merchandise',
      //   },
      //   {
      //     title: '建筑装饰',
      //     englishTitle: 'Architectural decoration',
      //   },
      // ],
      products: [],
      compony: [
        {
          name: '主题名称',
          title: '沈阳积莱商贸有限公司',
        },
        {
          name: '主体类型',
          title: '贸易商',
        },
        {
          name: '所在地区',
          title: '辽宁沈阳',
        },
        {
          name: '主体规模',
          title: '1-49人',
        },
        {
          name: '注册资本',
          title: '500万人民币',
        },
        {
          name: '注册年份',
          title: '2017',
        },
      ],
      compony1: [
        {
          name: '资料认证',
          title: '企业资料通过认证',
        },
        {
          name: '经营模式',
          title: '贸易商',
        },
        {
          name: '主营内容',
          title: '消杀耗材 小型医疗设备',
        },
        {
          name: '销售产品',
          title: '胶片 敷料 消杀用品',
        },
      ],
      productList: [],
      // productList: [
      //   {
      //     img: '/image/supplies-biological-one.png',
      //     title: '生物医药',
      //     status: '0',
      //   },
      //   {
      //     img: '/image/supplies-medical-one.png',
      //     title: '医疗敷料',
      //     status: '1',
      //   },
      //   {
      //     img: '/image/medical-supplies-one.png',
      //     title: '消杀用品',
      //     status: '1',
      //   },
      //   {
      //     img: '/image/medical-supplies-two.png',
      //     title: '消杀用品',
      //     status: '1',
      //   },
      //   {
      //     img: '/image/supplies-biological-two.png',
      //     title: '生物医药',
      //     status: '1',
      //   },
      //   {
      //     img: '/image/supplies-rawMaterial.png',
      //     title: '原料用品',
      //     status: '1',
      //   },
      //   {
      //     img: '/image/supplies-medical-two.png',
      //     title: '医疗敷料',
      //     status: '1',
      //   },
      //   {
      //     img: '/image/medical-supplies-three.png',
      //     title: '医疗敷料',
      //     status: '1',
      //   },
      // ],
      detaildialogVisible: false,
      certList: { home: [], basic: [], business: [] },
      newsList: [],
      newsDetail: {},
      productDetail: '',
      productTypeList: [],
      submitForm: {
        title: '',
        content: '',
        company_name: '',
        contact_name: '',
        contact_phone: '',
        wx: '',
      },
      paginationParams: {
        pageSize: 8,
        pageNum: 1,
        totalRow: 0,
        totalPage: 0,
        // isDone: false,
      },
      companyData: {},
      validCode: '',
      iptValidCode: '',
      newsStatus: false,
      drawer: false,
      limit: ()=>{},
    }
  },
  mounted() {
    this.queryProductType()
    this.queryCert()
    this.queryCompanyData()
    this.queryNews()
    this.createValidCode()
    this.limit = this.initLimit()
  },
  watch: {
    tabIndex: () => {
      window.scrollTo(0, 0)
    },
  },
  methods: {
    tabBtn(index) {
      this.drawer = false
      this.tabIndex = index
      this.newsStatus = false
      // this.createValidCode()
      for (let key in this.submitForm) {
        this.submitForm[key] = ''
        // console.warn(`clear ${key} value `)
      }
    },
    newsOpen(id) {
      this.queryNewsDetail(id).then((res) => {
        if (res.success) {
          this.newsDetail = res.data
          this.tabIndex = 3
          this.newsStatus = true
        }
      })
    },
    newsClose() {
      this.newsStatus = false
    },

    createValidCode() {
      let code = ''
      var codeLength = 4 //验证码的长度
      var selectChar = new Array(
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'M',
        'N',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      )
      for (var i = 0; i < codeLength; i++) {
        var charIndex = Math.floor(Math.random() * 32)
        code += selectChar[charIndex]
      }
      this.validCode = code
    },
    async queryCert() {
      //类型(1基本 2企业  空 所有)
      //是否首页展示(0否 1是 空 所有)

      let paramsArr = [
        {
          is_top: 1,
        },
        {
          type: 1,
          is_top: 0,
        },
        {
          type: 2,
          is_top: 0,
        },
      ]

      let promiseArr = []

      paramsArr.forEach((params, idx) => {
        promiseArr.push(
          new Promise((resolve, reject) => {
            this.$request.send('/web/queryCert', params).then((res) => {
              if (res.success) resolve(res.data)
              else reject(`promise ${idx + 1} error`)
            })
          })
        )
      })

      Promise.all(promiseArr)
        .then((arr) => {
          console.warn(arr)
          ;(this.certList.home = arr[0].list),
            (this.certList.basic = arr[1].list)
          this.certList.business = arr[2].list
        })
        .catch((error) => {
          console.error(error)
        })
    },
    queryCompanyData() {
      this.$request.send('/web/queryCompanyInfo', {}).then((res) => {
        let data = res.data

        this.companyData = data
        // this.compony.find((obj) => (obj.name == '主题名称')).title = res.data.c_name

        let key = [
          'c_name',
          'c_type',
          'c_area',
          'c_scale',
          'c_capital',
          'c_year',
        ]
        let key1 = ['c_data', 'c_model', 'c_main', 'c_products']

        key.forEach((value, idx) => (this.compony[idx].title = data[value]))
        key1.forEach((value, idx) => (this.compony1[idx].title = data[value]))
      })
    },
    queryProductType() {
      this.$request.send('/web/queryProductType', {}).then((res) => {
        this.productTypeList = res.data
        this.products = res.data
          .map((value) => {
            return { title: value.name }
          })
          .reverse()
        this.products.unshift({ title: 'holder' })
      })
    },
    queryNews() {
      this.$request
        .send('/web/queryNews', {})
        .then((res) => (this.newsList = res.data.list))
    },
    queryProduct(id) {
      let params = { ...this.paginationParams, id }
      delete params.totalRow
      delete params.totalPage
      // delete params.isDone
      this.$request.send('/web/queryProduct', params).then((res) => {
        this.productList = res.data.list
        this.paginationParams.totalRow = res.data.totalRow
        this.paginationParams.totalPage = res.data.totalPage
      })
    },
    browseDetail(id) {
      this.$request.send('/web/queryProductDetail', { id }).then((res) => {
        if (res.success) {
          this.detaildialogVisible = true
          this.productDetail = res.data.content
        } else this.$message.error('详情查询失败')
      })
    },
    initLimit(wait = 1000) {
      let pre = Date.now()
      console.log(pre)
      return () => {
        let now = Date.now()
        console.log(now)
        if (now - pre >= wait) {
          console.log('run')
          this.saveContact()
          pre = Date.now()
        }
      }
    },
    saveContact() {
      // if (
      //   !Object.entries(this.submitForm).every((arr) => {
      //     return ((arr[0] == 'contact_name' || arr[0] == 'contact_phone') && !arr[1] ) ? false : true
      //   })
      // )
      let that = this
      function checkPhoneNumber() {
        let reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/
        let tel = that.submitForm.contact_phone
        if (!reg.test(tel)) {
          that.$message({
            message: '手机号格式不正确',
            type: 'warning',
          })
          return;
        }
        return true
      }

      if (!this.submitForm.contact_name || !this.submitForm.contact_phone) {
        this.$message({
          message: '表单不完整',
          type: 'warning',
        })
        return
      }

      if (!checkPhoneNumber()) return;

      if (!this.validCode || this.validCode != this.iptValidCode) {
        this.$message({
          message: '验证码不正确',
          type: 'warning',
        })
        return
      }

      this.$request
        .send('/web/saveContact', { ...this.submitForm })
        .then((res) => {
          if (res.success) {
            this.$message({
              message: '提交成功！',
              type: 'success',
            })
            for (let key in this.submitForm) {
              // console.warn(key)
              this.submitForm[key] = ''
            }
          } else this.$message.error('提交失败')

          console.warn(`submit status : ${res.success}`)
        })
    },
    async queryNewsDetail(id) {
      return await this.$request.send('/web/queryNewsDetail', {
        id,
      })
    },
    handleCurrentChange(e) {
      this.paginationParams.pageNum = e
      console.warn(`page change ${e}`)
    },
  },
}
</script>


<style lang="scss" scoped>
@import '../styles/normal.css';
@import '../styles/media_query.css';

// .el-dialog__wrapper /deep/ .el-dialog {
//   width: 80%;
// }
</style>
