<template>
  <div>
    <div class="profile-top-div">
      <div class="news">
        <div class="news-title">公司介绍</div>
        <div class="news-hr">
          <div class="hr"></div>
          <div class="hr"></div>
        </div>
        <div class="news-english-title">COMPANY INTRO</div>
      </div>
      <!-- <div class="profile-content-div">
        沈阳积莱商贸有限公司成立于2017年。公司以尊重科学，注重质量，精诚服务的经营宗旨，致力于医疗器械行业各类医疗设备及耗材的销售服务。<br />
        公司资金﹑技术力量雄厚,拥有一批高素质管理人员,
        拥有先进的研发中心,专业生产各式棉签﹑棉棒。公司生产工艺先进,
        采用购置和自制的先进生产设备,
        木杆、塑料杆﹑纸棒、棉球、棉签等产品全部自主生产并加工完成。专业打造质优价廉型产品。<br />
        本着“追求精益，永无止境，企业才能做大做强”的核心竞争力,企业快速发展壮大,现以拥有5000平方米及厂房、办公室、宿舍为一体的综合楼，职工150余人,
        40余台棉签,棉棒,木制品,以及塑料制品等专业生产设备。<br />
        公司的主要产品有：卫生栓（内置棉条）、各式各样的木杆、塑料杆、纸杆棉签,
        塑料自封口袋、各种塑料盒等产品.并可根据客户需要生产各种规格,样式的产品。
      </div> -->
      <div
        class="html ql-container ql-snow profile-content-div"
        style="border: none"
      >
        <div
          class="ql-editor"
          data-gramm="false"
          contenteditable="false"
          data-placeholder="请输入内容"
          v-html="content"
        ></div>
      </div>
      <div class="profile-top-img">
        <img src="image/profile-content.png" alt="" />
      </div>
    </div>
    <div  v-if="dataLoad" class="contacts">
      <div class="contact-back-img">
        <img src="image/contact-people.png" alt="" />
      </div>
      <div  v-if="dataLoad" class="contact">
        <div class="contact-title">DEVELOPMENT<span>COURSE</span></div>
        <div class="contact-title2">
          <div class="contact-title2-hr"></div>
          <span>发展历程</span>
        </div>
        <div class="contact-content">
          <div class="contact-content-title">{{ currentAnchor.time }}</div>
          <div class="contact-content-title2"><p>年</p></div>
          <div class="contact-content-bor"></div>
          <div class="contact-content-title2 contact-content-width">
            {{ currentAnchor.content }}
          </div>
        </div>
        <div class="contact-time-whole">
         
          <div class="contact-time-div">
            <div class="contact-time" v-for="(v, k) in devHistory" :key="k">
              <div class="contact-time-contim" v-if="v.styleif == 1">
                {{ v.time }}
              </div>
              <div class="contact-time-null" v-if="v.styleif == 2"></div>
              <div class="contact-time-cont" v-if="v.styleif == 2">
                {{ v.time }}
              </div>
              <div class="contact-time-img" v-if="v.styleif == 1">
                <img src="image/profile-location.png" alt="" />
              </div>
              <div class="contact-time-radius" @click="radiusClick(v)"></div>
            </div>
          </div>
           <div class="contact-time-bor"></div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // profiletime: [
      //   { time: '2017', styleif: '1' },
      //   { time: '2018', styleif: '2' },
      //   { time: '2019', styleif: '2' },
      //   { time: '2020', styleif: '2' },
      //   { time: '2021', styleif: '2' },
      // ],
      devHistory: [],
      dataLoad:false
    }
  },
  mounted() {
    this.queryDevHistory()
  },
  computed: {
    currentAnchor() {
      for (let key in this.devHistory) {
        if (this.devHistory[key].styleif == 1) return this.devHistory[key]
      }
      return ''
    },
  },
  methods: {
    radiusClick(v) {
      for (let key in this.devHistory) {
        if (this.devHistory[key].styleif == 1) this.devHistory[key].styleif = 2
      }
      v.styleif = 1
      this.$forceUpdate()
    },
    queryDevHistory() {
      this.$request.send('/web/queryHistory', {}).then((res) => {
        let obj = {}
        res.data.list.forEach((data, idx) => {
          let value = {
            time: data.year,
            content: data.content,
            styleif: idx ? 2 : 1,
          }
          obj[data.year] = value
        })
        console.warn(obj)
        this.devHistory = obj
        this.dataLoad = true;
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@function sizeScale($oriValue, $ratio) {
  $unit: 1px;
  @return ($oriValue * $ratio) * $unit;
}

@function ratioConvert($ratio) {
  $unit: 1%;
  @return math.div((1 * 50), $ratio) * $unit;
}

// 100 * 50% = 120 * x
// x =  (100 * 50%) /120

.profile-top-div {
  padding-top: 40px;
  padding-bottom: 100px;
  position: relative;
  .news {
    .news-title {
      font-size: 36px;
      font-family: SourceHanSansCN-Medium;
      line-height: 100px;
      color: #333333;
      text-align: center;
    }

    .news-hr {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .hr {
        width: 267px;
        border: 2px solid #d2d2d2;
      }
    }

    .news-english-title {
      font-size: 36px;
      font-family: SourceHanSansCN-Medium;
      line-height: 48px;
      color: #e0b364;
      text-align: center;
      font-weight: 600;
    }
  }
  .profile-content-div {
    width: 968px;
    font-size: 26px;
    color: #292929;
    margin: 20px auto;
  }
  .profile-top-img {
    width: 720px;
    height: 490px;
    position: absolute;
    right: 100px;
    bottom: -20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.contacts {
  position: relative;
  
  .contact-back-img {
          position: relative;
      z-index: -10;
    img {
      width: 100%;
      height: 714px;
    }
  }

  .contact {
    width: 100vw;
    height: 714px;
    background-color: rgba($color: #34302d, $alpha: 0.9);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    .contact-title {
      height: 100px;
      font-size: 60px;
      font-family: SourceHanSansCN-Medium;
      color: #e1b261;
      margin: 60px 0 0 300px;

      span {
        font-size: 60px;
        font-family: SourceHanSansCN-Medium;
        color: #ffffff;
        margin: 159px 0 0 58px;
      }
    }
    .contact-title2 {
      display: flex;
      align-items: center;

      .contact-title2-hr {
        width: 420px;
        border: 5px solid #ffffff;
      }

      span {
        font-size: 36px;
        font-family: SourceHanSansCN-Medium;
        line-height: 100px;
        color: #ffffff;
        margin-left: 26px;
      }
    }
    .contact-content {
      width: 600px;
      height: 66px;
      margin: 40px auto;
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      .contact-content-title {
        font-size: 56px;
      }
      .contact-content-title2 {
        width: 15px;
        height: 60px;
        position: relative;
        font-size: 14px;
        p {
          position: absolute;
          bottom: 0px;
        }
      }
      .contact-content-bor {
        width: 1px;
        height: 58px;
        background: #ffffff;
      }
      .contact-content-width {
        width: 350px;
      }
    }
    .contact-time-whole {
      width: 560px;
      margin: 80px auto;
      .contact-time-div {
        width: 540px;
        display: flex;
        justify-content: space-between;
        .contact-time {
          display: flex;
          flex-direction: column;
          align-items: center;

          .contact-time-contim {
            font-size: 24px;
            color: #dfb15f;
          }
          .contact-time-cont {
            font-size: 16px;
            color: #ffffff;
          }
          .contact-time-null {
            height: 42px;
          }
          .contact-time-img {
            width: 24px;
            height: 30px;
            position: relative;
            top: 8px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .contact-time-radius {
            width: 15px;
            height: 15px;
            background-color: #ffffff;
            border-radius: 50%;
          }
        }
      }
      .contact-time-bor {
        // width: 540px;
        height: 0px;
        border: 2px solid #7b7975;
        position: relative;
        left: 20px;
        bottom: 8px;
        z-index: -1;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .profile-top-div {
    padding-top: 40px;
    padding-bottom: 100px;
    position: relative;
    .news {
      .news-title {
        font-size: 36px;
        font-family: SourceHanSansCN-Medium;
        line-height: 100px;
        color: #333333;
        text-align: center;
      }

      .news-title {
        font-size: sizeScale(36, 2);
        line-height: sizeScale(100, 2);
      }

      .news-hr {
        display: none;
        // .hr {
        //   width: sizeScale(267,2);
        //   border: sizeScale(2,2) solid #d2d2d2;
        // }
      }

      .news-english-title {
        font-size: sizeScale(36, 2);
        line-height: sizeScale(48, 2);
      }
    }

    .profile-content-div {
      width: sizeScale(968, 1.5);
      font-size: sizeScale(26, 2);
      margin: sizeScale(20, 2) auto;
    }
    .profile-top-img {
      width: sizeScale(720, 1.5);
      height: sizeScale(490, 1.5);
      position: absolute;
      right: sizeScale(100, 0.5);
      bottom: -20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .contacts {
    position: relative;

    .contact-back-img {
      display: none;

      // img {
      //   width: 100%;
      //   height: sizeScale(714,1.5);
      // }
    }

    .contact {
      width: 100vw !important;
      height: auto !important;
      position: static !important;
      float: left;

      .contact-title {
        height: sizeScale(100, 1.5);
        font-size: sizeScale(60, 1.5);
        margin: 60px 300px;
        width: auto;

        span {
          font-size: sizeScale(60, 1.5);
          margin: sizeScale(159, 2) 0 0 sizeScale(58, 2);
        }
      }
      .contact-title2 {
        .contact-title2-hr {
          width: sizeScale(420, 0.5);
          border: 5px solid #ffffff;
        }

        span {
          font-size: sizeScale(36, 2);
          font-family: SourceHanSansCN-Medium;
          line-height: sizeScale(100, 2);
          color: #ffffff;
          margin-left: 26px;
        }
      }
      .contact-content {
        width: sizeScale(600, 2);
        height: auto;
        margin: 40px auto;
        .contact-content-title {
          height: sizeScale(56, 3);
          font-size: sizeScale(56, 3);
          line-height: sizeScale(56, 3);
        }
        .contact-content-title2 {
          width: auto;
          height: auto;
          position: relative;
          font-size: sizeScale(14, 3);
          line-height: sizeScale(14, 3.5);
          display: flex;
          align-items: flex-end;
          p {
            position: relative;
            // bottom: 0px;
            margin-bottom: 20px;
          }
        }
        .contact-content-bor {
          width: sizeScale(1, 3);
          height: sizeScale(58, 3);
          margin-left: 30px;
        }
        .contact-content-width {
          width: sizeScale(350, 3);
          margin-left: 50px;
        }
      }

      .contact-time-whole {
        width: 560px;
        margin: 80px auto;
        position: relative;
        .contact-time-div {
          width: 540px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .contact-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            .contact-time-contim {
              font-size: sizeScale(24, 3);
              color: #dfb15f;
            }
            .contact-time-cont {
              font-size: sizeScale(16, 3);
              color: #ffffff;
            }
            .contact-time-null {
              height: 4sizescale (42, 3);
            }
            .contact-time-img {
              width: sizeScale(24, 3);
              height: sizeScale(30, 3);
              position: relative;
              top: sizeScale(8, 3);
              img {
                width: 100%;
                height: 100%;
              }
            }
            .contact-time-radius {
              width: sizeScale(15, 3);
              height: sizeScale(15, 3);
              background-color: #ffffff;
              border-radius: 50%;
            }
          }
        }
        .contact-time-bor {
          width: sizeScale(540, 1.05);
          height: 0px;
          border: 1px solid #7b7975;
          position: absolute;
          transform: rotateZ(90deg) translateX(50%);
          transform-origin: ratioConvert(1.05) 50%;
          top: 185px;
          left: auto;
          bottom: auto;
          // left: 20px;
          // bottom: 8px;
        }
      }
    }
  }
}
</style>